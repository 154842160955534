import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { twMerge } from "tailwind-merge";

interface Props {
  containerClassName?: string;
  inputClassName?: string;
  value: string;
  handleValue: (value: string) => void;
  arialLabel?: string;
  icon?: IconDefinition;
  placeholder?: string;
}

export const Input: FC<Props> = ({
  containerClassName,
  inputClassName,
  value,
  handleValue,
  arialLabel,
  icon,
  placeholder,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleValue(e.target.value);

  return (
    <div
      aria-label={`${arialLabel}'s container`}
      className={twMerge("relative w-full", containerClassName)}
    >
      <input
        type="text"
        aria-label={arialLabel}
        placeholder={placeholder}
        value={value}
        className={twMerge(
          "w-full h-14 pl-4 pr-9 text-sm bg-whiteGray border border-lightGray outline-none",
          inputClassName
        )}
        onChange={handleChange}
      />
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className="text-sm text-darkGray absolute top-1/2 right-3 -translate-y-1/2"
        />
      )}
    </div>
  );
};

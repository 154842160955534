import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface IProps extends PropsWithChildren {
  className?: string;
  type?: "button" | "submit";
  onClick?: () => void;
  arialLabel?: string;
}

export const Button = ({
  className,
  type = "button",
  children,
  onClick,
  arialLabel,
}: IProps) => (
  <button
    aria-label={arialLabel}
    type={type}
    className={twMerge(
      "w-full p-4 bg-primary hover:bg-transparent border border-primary text-white hover:text-primary font-extrabold cursor-pointer duration-300 tracking-wide",
      className
    )}
    onClick={onClick}
  >
    {children}
  </button>
);

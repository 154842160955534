import { FC } from "react";
import { useLocation } from "react-router-dom";

import { NotFound } from "../components/layout/not-found.component";
import { SearchResults } from "../components/layout/search-results.component";

export const NotFoundPage: FC = () => {
  const location = useLocation();

  return (
    <section
      aria-label="Not found page content"
      className="py-16 md:py-5 px-5 bg-whiteGray flex justify-center items-center"
    >
      {!location.search ? <NotFound /> : <SearchResults />}
    </section>
  );
};

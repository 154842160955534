import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Zoom } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";

import { Button } from "../common/button.component";
import { SectionId } from "../../enums";
import { packageBenefits, packagesContent } from "../../constants";
import { PackageItemProps } from "../../types";
import { MY_ACCOUNT_PATH } from "../../router/constants";

export const Pricing: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onSubscribeButtonClick = () => navigate(MY_ACCOUNT_PATH);

  const renderPackageBenefits = (benefit: string) => (
    <li
      key={benefit}
      className="text-gray text-lg font-medium mb-5 last:mb-0 flex justify-center text-center"
    >
      <p>
        <span>
          <FontAwesomeIcon icon={faCheck} className="text-primary mr-3" />
        </span>
        {t(benefit)}
      </p>
    </li>
  );

  const renderPackageItem = ({ days, price }: PackageItemProps) => (
    <li
      key={days}
      aria-label={`Subscription package for ${days} days`}
      className="rounded-lg bg-white overflow-hidden lg:basis-[45%] md:basis-auto"
    >
      <div
        className={twMerge(
          "py-5 xl:py-4 lg:py-7 md:py-11 sm:py-9 flex justify-center text-5xl font-bold",
          days === 180 ? "bg-primary text-white" : "bg-lightGray text-black"
        )}
      >
        {t("pricing.days", { days })}
      </div>

      <div aria-label="Package information" className="px-5 pt-8 pb-12">
        <div
          aria-label="Package price"
          className="pb-4 mb-9 border-b-4 border-lightGray text-primary text-6xl font-bold flex justify-center items-center"
        >
          <sup className="text-4xl">$</sup>
          {price}
        </div>

        <ul
          aria-label="Package benefits"
          className="flex flex-col justify-center mb-6"
        >
          {packageBenefits.map(renderPackageBenefits)}
        </ul>

        <Button className="text-xs uppercase" onClick={onSubscribeButtonClick}>
          Subscribe
        </Button>
      </div>
    </li>
  );

  return (
    <section
      id={SectionId.PRICING}
      aria-label="Pricing and Packages"
      className="py-24 lg:py-28 md:py-20 md:px-6 sm:py-16 flex flex-col items-center bg-pricing sm:bg-repeat-y bg-secondary"
    >
      <Zoom triggerOnce>
        <h2 className="text-white text-5xl md:text-4xl font-bold text-center mb-5">
          {t("pricing.pricingAndPackages")}
        </h2>
      </Zoom>
      <Zoom triggerOnce className="flex justify-center">
        <p className="text-white text-2xl font-normal text-center w-[65%] lg:w-[80%] sm:w-[75%] mb-20 xl:mb-16 md:mb-14">
          {t("pricing.readyToSubscribe")}
        </p>
      </Zoom>
      <Zoom triggerOnce>
        <div className="flex justify-center">
          <ul
            aria-label="Subscription packages"
            className="list-none w-[90%] lg:w-full flex gap-x-8 lg:gap-x-0 lg:gap-y-8 lg:flex-wrap lg:justify-evenly md:flex-col md:flex-nowrap"
          >
            {packagesContent.map(renderPackageItem)}
          </ul>
        </div>
      </Zoom>
    </section>
  );
};

import React, { FC } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "../common/button.component";
import { useGetCountryInformation } from "../../api";
import { FREE_TRIAL_PATH } from "../../router/constants";

export const Header: FC = () => {
  const { t } = useTranslation();

  const { data: countryName } = useGetCountryInformation();

  const navigate = useNavigate();

  const onStartTrialClick = () => navigate(FREE_TRIAL_PATH);

  return (
    <header className="relative w-full md:px-10 py-20 md:py-10 flex justify-center bg-header bg-center bg-cover before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-black before:bg-opacity-50">
      <div arial-label="Header content" className="max-w-7xl w-full z-10">
        <div className="w-1/3 md:w-full mb-2.5 lg:mb-0 md:mb-2.5 lg:ml-10 md:ml-0">
          <Slide direction="right" triggerOnce>
            <Fade triggerOnce>
              <div className="w-64 md:w-full h-12 pl-3.5 border-l-8 border-primary bg-black bg-opacity-50 flex items-center">
                <p
                  aria-label="Motto"
                  className="text-lg font-semibold text-white"
                >
                  {t("header.embraceTheVision")}
                </p>
              </div>
            </Fade>
          </Slide>
        </div>

        <div className="px-5 lg:px-14 md:px-1 py-5 bg-black bg-opacity-50 text-white">
          <Slide direction="right" triggerOnce>
            <Fade triggerOnce>
              <h1 className="text-6xl md:text-3xl font-black mb-14">
                <div className="text-primary">{t("common.pizzaTime")}</div>
                {t("header.leadingProvider", {
                  countryName: countryName ?? "",
                })}
              </h1>
              <p
                aria-label="Subscription offer"
                className="text-4xl md:text-3xl font-bold mb-8 md:mb-3 leading-relaxed w-full lg:max-w-3/4 md:max-w-full"
              >
                {t("header.accessYourFavorite")} &nbsp;
                <span aria-label="Subscription price" className="text-primary">
                  {t("header.pricePerMonth")}
                </span>
                *
              </p>
              <p
                aria-label="Note"
                className="max-w-1/2 w-full lg:max-w-3/4 md:max-w-full text-2xl font-normal mb-20"
              >
                * {t("header.note")}
              </p>
            </Fade>
          </Slide>

          <Slide direction="up" triggerOnce>
            <Fade triggerOnce>
              <Button
                arialLabel="Start trial"
                className="w-44 md:w-36 h-14 md:h-12 p-3 rounded bg-secondary hover:bg-secondary hover:bg-opacity-70 hover:text-white font-extrabold text-sm uppercase shadow-outer !shadow-primary md:ml-20"
                onClick={onStartTrialClick}
              >
                {t("header.startTrial")}
              </Button>
            </Fade>
          </Slide>
        </div>
      </div>
    </header>
  );
};

import React, { FC } from "react";

import { PaymentMethods } from "./payment-methods.component";
import { Navbar } from "./navbar.component";
import { ContactUs } from "./contact-us-component";
import { Footer } from "./footer.component";
import { Outlet, useLocation } from "react-router-dom";
import { WebsiteTitle } from "../common/website-title.component";
import { CHAT_PATH } from "../../router/constants";

export const MainLayout: FC = () => {
  const location = useLocation();

  const pathname = location.pathname.toLowerCase().replace(/[^\w]/g, "");
  const isChatPage = pathname === CHAT_PATH.replace(/[^\w]/g, "");

  return (
    <div className="flex flex-col h-screen">
      <PaymentMethods />
      <Navbar />

      <Outlet />

      {!isChatPage && (
        <>
          <ContactUs />
          <Footer />
        </>
      )}
      <WebsiteTitle />
    </div>
  );
};

import React from "react";
import { Helmet } from "react-helmet-async";

export const GoogleTagManager = () => {
  const REACT_APP_GOOGLE_TAG_MANAGER_ID =
    process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
  const REACT_APP_GOOGLE_TAG_MANAGER_JS_ID =
    process.env.REACT_APP_GOOGLE_TAG_MANAGER_JS_ID;

  const gtmIframeUrl = `https://www.googletagmanager.com/ns.html?id=${REACT_APP_GOOGLE_TAG_MANAGER_ID}`;

  return (
    <Helmet>
      <script>
        {`
          (function(w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
              'gtm.start': new Date().getTime(),
              event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', '${REACT_APP_GOOGLE_TAG_MANAGER_ID}');
        `}
      </script>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${REACT_APP_GOOGLE_TAG_MANAGER_JS_ID}`}
      ></script>

      <noscript>
        {`<iframe
          title="googletagmanager"
          src="${gtmIframeUrl}"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        />`}
      </noscript>
    </Helmet>
  );
};

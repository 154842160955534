import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { Input } from "../common/input.component";

export const NotFound: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [query, setQuery] = useState("");

  const handleQuery = (value: string) => setQuery(value);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (query) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("s", query);

      navigate(`?${searchParams.toString()}`);
    }
  };

  return (
    <div className="max-w-xl w-full bg-white flex flex-col justify-center items-center py-20 lg:py-12 md:py-5 px-9">
      <h2
        aria-label="Page doesn't exist"
        className="text-secondary text-3xl text-center font-normal mb-5"
      >
        {t("notFoundPage.pageNotExist")}
      </h2>
      <p
        aria-label="Faulty link"
        className="text-darkGray text-xl text-center font-bold mb-5"
      >
        {t("notFoundPage.faultyLink")}
      </p>
      <form
        aria-label="Search form"
        className="max-w-96 w-full"
        onSubmit={handleSubmit}
      >
        <Input
          value={query}
          handleValue={handleQuery}
          icon={faSearch}
          arialLabel="Search"
          placeholder={`${t("common.search")}...`}
        />
      </form>
    </div>
  );
};

import { RouterProvider } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";

import "./config/i18n";
import { router } from "./router/main.router";
import { queryClient } from "./config/query-client";
import { MetaPixel } from "./connections/meta-pixel.connection";

import "./App.css";
import { GoogleTagManager } from "./connections/google-tag-manager.connection";
// import { HubSpot } from "./connections/hubspot.connection";
import { LiveChat } from "./connections/live-chat.connection";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <MetaPixel />
        <GoogleTagManager />
        {/* <HubSpot /> */}
        <LiveChat />
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;

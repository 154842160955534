import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { CountryInformationResponse } from "../types";

const getCountryInformation = async (): Promise<string | Error> => {
  const { data } = await axios.get<CountryInformationResponse>(
    process.env.REACT_APP_COUNTRY_INFO_LINK ?? ""
  );

  return data.countryName;
};

export const useGetCountryInformation = () => {
  const query = useQuery(["countryInformation"], getCountryInformation, {
    retry: false,
  });
  return query;
};

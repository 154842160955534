import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { Slide } from "react-awesome-reveal";

import devices from "../../images/devices.webp";
import devices300x263 from "../../images/devices-300x263.webp";
import devices768x673 from "../../images/devices-768x673.webp";
import { CircleIcon } from "../common/circle.icon";
import { SectionId } from "../../enums";
import { ServiceItemProps } from "../../types";
import { servicesContent } from "../../constants";

export const Services: FC = () => {
  const { t } = useTranslation();

  const renderServiceItem = (
    { title, text }: ServiceItemProps,
    index: number
  ) => (
    <li key={title} className="basis-1/2 xl:basis-full px-5 lg:px-0">
      <div className="mb-5 flex md:flex-col items-center md:items-start gap-x-4 md:gap-y-4">
        <CircleIcon
          className={twMerge(
            "w-12",
            index % 2 ? "text-secondary" : "text-primary"
          )}
        />

        <h3 className="text-secondary text-2xl font-bold leading-10">
          {t(title)}
        </h3>
      </div>
      <p className="text-gray text-lg font-normal leading-6 ml-16 md:ml-0">
        {t(text)}
      </p>
    </li>
  );

  return (
    <section
      id={SectionId.SERVICES}
      aria-label="Services"
      className="pt-20 pb-5 xl:pt-16 xl:pb-14 lg:pt-32 lg:pb-32 md:pt-24 md:pb-24 sm:pt-20 flex justify-center"
    >
      <div className="w-[95%] lg:w-[80%] pr-14 xl:pr-0 lg:pr-0 flex lg:flex-col gap-x-8">
        <Slide direction="up" triggerOnce className="basis-1/3">
          <div className="h-full flex flex-col justify-between gap-y-40 lg:mb-12">
            <h2 className="w-[80%] xl:w-full ml-auto xl:ml-0 text-secondary text-4xl font-bold leading-10">
              {t("services.imagineWatchCancel")}
            </h2>
            <img
              className="lg:hidden"
              loading="lazy"
              decoding="async"
              width="815"
              height="714"
              src={devices}
              alt="Electronic devices"
              srcSet={`${devices} 815w, ${devices300x263} 300w, ${devices768x673} 768w`}
              sizes="(max-width: 815px) 100vw, 815px"
            />
          </div>
        </Slide>

        <Slide direction="up" triggerOnce className="basis-2/3">
          <ul
            aria-label="Service benefits"
            className="list-none flex lg:flex-col flex-wrap lg:flex-nowrap  xl:gap-y-12"
          >
            {servicesContent.map(renderServiceItem)}
          </ul>
        </Slide>
      </div>
    </section>
  );
};

export const HOME_PATH = "/";

export const MY_ACCOUNT_PATH = "/portal";

export const CREATE_ACCOUNT_PATH = "/create-account";

export const FREE_TRIAL_PATH = "/trial";

export const QUICK_PAYMENT_PATH = "/quick-payment/";

export const CHAT_PATH = "/pizzatime-chat"

import {
  faTwitter,
  faTiktok,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";

import { LinkModule, RouterPath, SectionId } from "../enums";
import {
  ServiceItemProps,
  NavigationLinkProps,
  PackageItemProps,
  FeedbackItemProps,
  ContactProps,
  ManageAccountButtonsProps,
} from "../types";

import leeDaniel from "../images/lee-daniel.png";
import kathrynShelton from "../images/kathryn-shelton.png";
import bettyMoore from "../images/betty-moore.png";
import { generateLink } from "../utils/generate-link";
import {
  CREATE_ACCOUNT_PATH,
  FREE_TRIAL_PATH,
  MY_ACCOUNT_PATH,
} from "../router/constants";

export const navigationLinkContent: NavigationLinkProps[] = [
  {
    name: "navigation.homePage",
    id: SectionId.HOME_PAGE,
  },
  {
    name: "navigation.services",
    id: SectionId.SERVICES,
  },
  {
    name: "navigation.pricingPackages",
    id: SectionId.PRICING,
  },
  {
    name: "navigation.faq",
    id: SectionId.FAQ,
    href: generateLink(LinkModule.FAQ),
  },
  {
    name: "common.contactUs",
    id: SectionId.CONTACT_US,
  },
];

export const manageAccountButtons: ManageAccountButtonsProps[] = [
  {
    name: "navigation.myAccount",
    path: MY_ACCOUNT_PATH,
  },
  {
    name: "navigation.createAccount",
    path: CREATE_ACCOUNT_PATH,
  },
  {
    name: "navigation.freeTrial",
    path: FREE_TRIAL_PATH,
  },
];

export const servicesContent: ServiceItemProps[] = [
  {
    title: "services.onTheGo",
    text: "services.onTheGoText",
  },
  {
    title: "services.noNonsensePricing",
    text: "services.noNonsensePricingText",
  },
  {
    title: "services.watchBreakingNews",
    text: "services.watchBreakingNewsText",
  },
  {
    title: "services.ourService",
    text: "services.ourServiceText",
  },
];

export const packageBenefits = [
  "pricing.devicesPerUser",
  "pricing.hdChannels",
  "pricing.countries",
  "pricing.categories",
  "pricing.vodLibrary",
];

export const packagesContent: PackageItemProps[] = [
  {
    days: 30,
    price: 24.99,
  },
  {
    days: 90,
    price: 51.99,
  },
  {
    days: 180,
    price: 89.99,
  },
  {
    days: 365,
    price: 159.99,
  },
];

export const feedbacksContent: FeedbackItemProps[] = [
  {
    image: leeDaniel,
    name: "Lee Daniel",
    city: "Louisville, KY",
    feedback: "feedbacks.leeDanielText",
  },
  {
    image: kathrynShelton,
    name: "Kathryn Shelton",
    city: "North Hempstead, NY",
    feedback: "feedbacks.kathrynSheltonText",
  },
  {
    image: bettyMoore,
    name: "Betty Moore",
    city: "Philadelphia, PA",
    feedback: "feedbacks.bettyMooreText",
  },
];

export const contacts: ContactProps[] = [
  {
    name: "Facebook",
    icon: faFacebook,
    link: process.env.REACT_APP_FACEBOOK_LINK ?? "",
  },
  {
    name: "Tiktok",
    icon: faTiktok,
    link: process.env.REACT_APP_TIKTOK_LINK ?? "",
  },
  {
    name: "Twitter",
    icon: faTwitter,
    link: process.env.REACT_APP_TWITTER_LINK ?? "",
  },
];

export const createAccountIframePath = "/register";

export const freeTrialIframePath = `${createAccountIframePath}?trial=true`;

export const websiteTitle = {
  [RouterPath.MY_ACCOUNT_PATH]: "navigation.clientPortal",
  [RouterPath.CREATE_ACCOUNT_PATH]: "navigation.createAccount",
  [RouterPath.FREE_TRIAL_PATH]: "navigation.trial",
  [RouterPath.QUICK_PAYMENT_PATH]: "navigation.quickPayment",
  [RouterPath.CHAT_PATH]: "navigation.chat",
};

import React, { FC } from "react";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "./button.component";
import { manageAccountButtons } from "../../constants";
import { ManageAccountButtonsProps } from "../../types";

interface Props {
  listClassName?: string;
  itemClassName?: string;
  buttonClassName?: string;
  onButtonClick?: () => void;
}

export const ManageAccountList: FC<Props> = ({
  listClassName,
  itemClassName,
  buttonClassName,
  onButtonClick,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();

  const renderManageAccountButton = ({
    name,
    path,
  }: ManageAccountButtonsProps) => {
    const handleClick = () => {
      onButtonClick?.();
      navigate(path);
    };

    return (
      <li
        aria-label={name}
        key={name}
        className={twMerge("basis-1/3", itemClassName)}
      >
        <Button
          className={twMerge(
            "p-2 h-11  text-sm font-bold border-transparent rounded-none hover:bg-primary hover:text-white text-nowrap",
            location.pathname === path
              ? "bg-primary text-white"
              : "bg-transparent text-black",
            buttonClassName
          )}
          onClick={handleClick}
        >
          {t(name)}
        </Button>
      </li>
    );
  };

  return (
    <ul
      aria-label="Manage account buttons"
      className={twMerge(
        "list-none flex justify-center items-center",
        listClassName
      )}
    >
      {manageAccountButtons.map(renderManageAccountButton)}
    </ul>
  );
};

import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Zoom, Slide, Fade } from "react-awesome-reveal";

import { Button } from "../common/button.component";
import { SectionId } from "../../enums";
import { contacts } from "../../constants";
import { ContactProps } from "../../types";

export const ContactUs: FC = () => {
  const { t } = useTranslation();

  const handleChatButtonClick = () => window.LiveChatWidget.call("maximize");

  const renderSocialMediaLink = ({ name, icon, link }: ContactProps) => (
    <li key={name} aria-label={`${name}'s link`} className="cursor-pointer">
      <a href={link} target="_blank" rel="noreferrer noopener">
        <FontAwesomeIcon icon={icon} className="text-4xl text-primary" />
      </a>
    </li>
  );

  return (
    <section
      id={SectionId.CONTACT_US}
      aria-label="Contact us"
      className="relative py-28 sm:py-20 px-2.5 flex justify-center bg-contact-us bg-auto bg-center"
    >
      <div className="max-w-2xl w-full lg:flex lg:justify-center">
        <div className="max-w-[70%] w-full lg:max-w-165 sm:max-w-full py-10 pl-5 pr-2.5 flex flex-col gap-y-32 sm:gap-y-20 bg-black bg-opacity-50 text-white">
          <Zoom triggerOnce>
            <h2 className="text-6xl sm:text-3xl font-bold tracking-wide flex flex-wrap">
              {t("contactUs.contact")}
              &nbsp;
              <span className="text-primary">{t("common.pizzaTime")}</span>
              &nbsp;
              {t("contactUs.now")}
            </h2>
          </Zoom>

          <div>
            <Zoom triggerOnce delay={100}>
              <p
                aria-label="About support"
                className="text-2xl md:text-xl font-normal mb-14 tracking-wide"
              >
                {t("contactUs.useChatBox")}
                &nbsp;
                <span className="text-primary">
                  {t("contactUs.liveSupport")}
                </span>
                . &nbsp;
                {t("contactUs.freeToContactUs")}
              </p>
            </Zoom>

            <Slide direction="right" triggerOnce>
              <Fade triggerOnce delay={100}>
                <Button
                  arial-label="Contact us"
                  className="w-40 p-3 mb-16 rounded-lg font-semibold capitalize border-4"
                  onClick={handleChatButtonClick}
                >
                  {t("common.contactUs")}
                </Button>
              </Fade>
            </Slide>

            <p
              aria-label="Follow us"
              className="text-4xl sm:text-2xl font-semibold mb-5"
            >
              {t("contactUs.followUs")}
            </p>
            <Slide direction="up" triggerOnce>
              <ul
                aria-label="Social media links"
                className="list-none flex gap-x-10"
              >
                {contacts.map(renderSocialMediaLink)}
              </ul>
            </Slide>
          </div>
        </div>
      </div>
    </section>
  );
};

import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import { websiteTitle } from "../../constants";
import { RouterPath } from "../../enums";
import { useTranslation } from "react-i18next";
import { HOME_PATH } from "../../router/constants";

export const WebsiteTitle = () => {
  const location = useLocation();

  const { t } = useTranslation();

  const REACT_APP_WEBSITE_TITLE = process.env.REACT_APP_WEBSITE_TITLE;

  const pageTitle = () => {
    if (location.pathname === HOME_PATH) {
      return REACT_APP_WEBSITE_TITLE;
    } else {
      const pageTitle =
        websiteTitle[location.pathname as RouterPath] ??
        "navigation.notFoundPage";
      return `${t(pageTitle)} - ${REACT_APP_WEBSITE_TITLE}`;
    }
  };

  return (
    <Helmet>
      <title>{pageTitle()}</title>
    </Helmet>
  );
};

import { LinkModule } from "../enums";

export const generateLink = (module: LinkModule, additionalPath?: string) => {
  if (process.env.REACT_APP_BASE_LINK) {
    const generatedLink = process.env.REACT_APP_BASE_LINK.replace(
      "~module~",
      module
    );
    return `${generatedLink}${additionalPath ?? ""}`;
  }
  return "";
};

import React, { FC } from "react";
import { Fade, Slide } from "react-awesome-reveal";

import { Logo } from "../common/logo.component";
import secureGlobalSign from "../../images/secure-global-sign.png";
import bitcoin from "../../images/bitcoin.png";

export const Footer: FC = () => {
  return (
    <section
      aria-label="Footer"
      className="py-8 px-2.5 bg-black flex justify-center"
    >
      <div className="w-320 lg:w-3/4 flex md:flex-col justify-between items-center md:gap-y-8">
        <Slide triggerOnce>
          <Fade triggerOnce>
            <Logo className="md:w-99 sm:w-full" />
          </Fade>
        </Slide>

        <Slide
          direction="right"
          triggerOnce
          className="w-1/2 flex md:justify-between items-center gap-x-28 md:gap-x-2 pl-36 lg:pl-0"
        >
          <Fade triggerOnce>
            <img
              loading="lazy"
              width="88"
              height="35"
              src={secureGlobalSign}
              alt="Secure global sign"
            />
            <img
              loading="lazy"
              width="28"
              height="38"
              src={bitcoin}
              alt="Bitcoin"
            />
          </Fade>
        </Slide>
      </div>
    </section>
  );
};

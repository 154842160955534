import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-awesome-reveal";
import { FeedbackItemProps } from "../../types";
import { feedbacksContent } from "../../constants";

export const Feedbacks: FC = () => {
  const { t } = useTranslation();

  const renderFeedbackItem = ({
    image,
    name,
    city,
    feedback,
  }: FeedbackItemProps) => (
    <li
      key={name}
      aria-label={`${name}'s feedback`}
      className="flex flex-col items-center lg:mb-16 lg:last:mb-0"
    >
      <p
        aria-label="Feedback content"
        className="text-gray text-lg text-center font-normal leading-snug mb-5"
      >
        {t(feedback)}
      </p>
      <div className="flex items-center gap-x-4">
        <img
          loading="lazy"
          decoding="async"
          width="60"
          height="60"
          src={image}
          alt={name}
        />
        <div aria-label="Feedback author">
          <p className="text-secondary text-2xl font-bold leading-10">{name}</p>
          <p className="text-black text-sm  font-extrabold leading-5 uppercase tracking-wide">
            {city}
          </p>
        </div>
      </div>
    </li>
  );

  return (
    <Zoom triggerOnce>
      <section
        aria-label="Feedbacks"
        className="py-20 flex flex-col items-center bg-feedbacks lg:bg-none bg-center bg-cover 2xl:bg-auto bg-no-repeat bg-white"
      >
        <h2 className="mb-5 text-black text-5xl md:text-4xl font-bold text-center">
          {t("feedbacks.aboutUs")}
        </h2>

        <ul className="w-2/3 2xl:w-3/4 xl:w-[95%] lg:w-[80%] sm:w-[75%] flex lg:flex-col gap-x-10 2xl:gap-x-6">
          {feedbacksContent.map(renderFeedbackItem)}
        </ul>
      </section>
    </Zoom>
  );
};

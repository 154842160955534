export enum SectionId {
  NAVIGATION = "navigation",
  HOME_PAGE = "home",
  SERVICES = "services",
  PRICING = "pricing",
  FAQ = "faq",
  CONTACT_US = "contact-us",
}

export enum LinkModule {
  FAQ = "faq",
  PORTAL = "portal",
  PUBLIC = "public",
}

export enum RouterPath {
  MY_ACCOUNT_PATH = "/portal",
  CREATE_ACCOUNT_PATH = "/create-account",
  FREE_TRIAL_PATH = "/trial",
  QUICK_PAYMENT_PATH = "/quick-payment/",
  CHAT_PATH = "/pizzatime-chat"
}

import React, { FC } from "react";
import { Zoom } from "react-awesome-reveal";

import shakepay from "../../images/shakepay.png";
import shakepay18x6 from "../../images/shakepay-18x6.png";
import bitcoin from "../../images/bitcoin-logo.png";
import bitcoin18x7 from "../../images/bitcoin-logo-18x7.png";
import bitcoin300x119 from "../../images/bitcoin-logo-300x119.png";
import bitcoin3768x306 from "../../images//bitcoin-logo-768x306.png";
import cashApp from "../../images/cashapp.png";
import cashApp18x4 from "../../images/cashapp-18x4.png";
import { SectionId } from "../../enums";

export const PaymentMethods: FC = () => (
  <section
    id={SectionId.HOME_PAGE}
    aria-label="Payment methods"
    className="h-16 py-1 px-2.5 flex justify-center items-center bg-secondary"
    data-id="anchoredSections"
  >
    <Zoom
      triggerOnce
      className="max-w-3xl w-full h-full bg-white bg-opacity-20 rounded-4xl flex justify-center items-center px-3"
    >
      <div className="h-full w-1/2 sm:w-full flex items-center justify-between sm:justify-center gap-3 flex-wrap">
        <img
          loading="lazy"
          width="86"
          height="28"
          src={shakepay}
          alt="Shakepay"
          srcSet={`${shakepay} 150w, ${shakepay18x6} 18w`}
          sizes="(max-width: 150px) 100vw, 150px"
        />
        <img
          loading="lazy"
          width="86"
          height="34"
          src={bitcoin}
          alt="Bitcoin"
          srcSet={`${bitcoin} 791w, ${bitcoin300x119} 300w, ${bitcoin3768x306} 768w, ${bitcoin18x7} 18w`}
          sizes="(max-width: 791px) 100vw, 791px"
        />
        <img
          loading="lazy"
          width="86"
          height="20"
          src={cashApp}
          alt="Cash app"
          srcSet={`${cashApp} 187w, ${cashApp18x4} 18w`}
          sizes="(max-width: 187px) 100vw, 187px"
        />
      </div>
    </Zoom>
  </section>
);

import { FC, useEffect } from "react";
import SlidingPane from "react-sliding-pane";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { NavigationList } from "./navigation-list.component";
import { ManageAccountList } from "./manage-account-list.component";
import { SectionId } from "../../enums";

interface Props {
  activeLinkId: SectionId | null;
  onLinkClick: (id: SectionId) => void;
  isPanelOpen: boolean;
  onClose: () => void;
}

export const MenuSlidingPanel: FC<Props> = ({
  isPanelOpen,
  activeLinkId,
  onLinkClick,
  onClose,
}) => {
  useEffect(() => {
    if (isPanelOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isPanelOpen]);

  return (
    <SlidingPane
      className={
        "hidden 2xl:flex 2xl:!w-[20%] lg:!w-[30%] md:!w-[40%] sm:!w-full relative"
      }
      overlayClassName="!bg-black !bg-opacity-30 z-30"
      isOpen={isPanelOpen}
      onRequestClose={onClose}
      hideHeader
    >
      <FontAwesomeIcon
        icon={faTimes}
        className="text-2xl text-lightGray hover:text-secondary cursor-pointer absolute top-3 right-3"
        onClick={onClose}
      />

      <NavigationList
        listClassName="flex-col"
        itemClassName="px-0 [&>a]:pl-0"
        onLinkClick={onLinkClick}
        activeLinkId={activeLinkId}
      />
      <ManageAccountList
        listClassName="hidden sm:flex flex-col gap-y-2 px-0 pt-5 mt-5 w-full border-t border-lightGray"
        itemClassName="w-full"
        buttonClassName="border-lightGray rounded-lg w-full"
        onButtonClick={onClose}
      />
    </SlidingPane>
  );
};

import { RouteObject } from "react-router-dom";

import { HomePage } from "../pages/home.page";
import { NotFoundPage } from "../pages/not-found.page";
import { MyAccountPage } from "../pages/my-account.page";
import { CreateAccountPage } from "../pages/create-account.page";
import { FreeTrialPage } from "../pages/free-trial.page";
import { QuickPayment } from "../pages/quick-payment.page";
import {
  CHAT_PATH,
  CREATE_ACCOUNT_PATH,
  FREE_TRIAL_PATH,
  HOME_PATH,
  MY_ACCOUNT_PATH,
  QUICK_PAYMENT_PATH,
} from "./constants";
import { MainLayout } from "../components/layout/main-layout.component";
import { ChatPage } from "../pages/chat.page";

export const routes: RouteObject[] = [
  {
    path: HOME_PATH,
    element: <MainLayout />,
    children: [
      {
        path: HOME_PATH,
        element: <HomePage />,
      },
      {
        path: MY_ACCOUNT_PATH,
        element: <MyAccountPage />,
      },
      {
        path: CREATE_ACCOUNT_PATH,
        element: <CreateAccountPage />,
      },
      {
        path: FREE_TRIAL_PATH,
        element: <FreeTrialPage />,
      },
      {
        path: QUICK_PAYMENT_PATH,
        element: <QuickPayment />,
      },
      {
        path: CHAT_PATH,
        element: <ChatPage />,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
];

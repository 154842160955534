import { FC } from "react";

import logo from "../../images/logo.png";
import { twMerge } from "tailwind-merge";

interface Props {
  className?: string;
}

export const Logo: FC<Props> = ({ className }) => (
  <a
    href="/"
    rel="noreferrer noopener"
    className="w-full flex justify-center items-center"
  >
    <img
      src={logo}
      alt="Pizzatime"
      loading="lazy"
      width="158"
      height="95"
      className={twMerge("max-h-28 object-contain", className)}
    />
  </a>
);

import { FC, useEffect } from "react";

export const ChatPage: FC = () => {

useEffect(() => {
    if (window.LiveChatWidget) {
      window.LiveChatWidget.call("maximize");
    }
  }, []);

  return (
    <section className="relative flex-1 bg-header bg-center bg-cover before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-black before:bg-opacity-50" />
  );
};

import React, { FC, useEffect } from "react";

import { IframeContainer } from "../components/common/iframe-container.component";
import { LinkModule } from "../enums";
import { generateLink } from "../utils/generate-link";
import { freeTrialIframePath } from "../constants";

export const FreeTrialPage: FC = () => {
  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, []);

  const link = generateLink(LinkModule.PORTAL, freeTrialIframePath);

  return <IframeContainer title="Create Account" link={link} id="freeTrial" />;
};

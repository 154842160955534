import React, { FC, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
import { Zoom, Slide, Fade } from "react-awesome-reveal";

import { Logo } from "../common/logo.component";
import { MenuSlidingPanel } from "../common/menu-sliding-panel.component";
import { NavigationList } from "../common/navigation-list.component";
import { ManageAccountList } from "../common/manage-account-list.component";
import { SectionId } from "../../enums";
import { useLocation } from "react-router-dom";
import { HOME_PATH } from "../../router/constants";
import { scrollIntoElementById } from "../../utils/scroll-into-element-by-id";

export const Navbar: FC = () => {
  const location = useLocation();

  const isHomePage = location.pathname === HOME_PATH;

  const [isSlidingPanelVisible, setIsSlidingPanelVisible] = useState(false);
  const [activeLinkId, setActiveLinkId] = useState<SectionId | null>(
    isHomePage
      ? location.hash
        ? (location.hash.replace("#", "") as SectionId)
        : SectionId.HOME_PAGE
      : null
  );

  useEffect(() => {
    if (location.hash) {
      const hashId = location.hash.replace("#", "") as SectionId;

      const searchedId = hashId || activeLinkId;

      scrollIntoElementById(searchedId, "auto");
      location.hash = "";
    }
  }, [location.hash]);

  const openSlidingPanel = () => setIsSlidingPanelVisible(true);

  const closeSlidingPanel = () => setIsSlidingPanelVisible(false);

  const handleLinkClick = (id: SectionId) => {
    id !== SectionId.FAQ && setActiveLinkId(id);
    scrollIntoElementById(id);
    isSlidingPanelVisible && closeSlidingPanel();
  };

  return (
    <>
      <nav
        id={SectionId.NAVIGATION}
        aria-label="Navigation"
        className="sticky top-0 min-h-28 z-30 px-5 bg-white flex items-center justify-between"
      >
        <Zoom
          triggerOnce
          className="flex justify-center sm:justify-start items-center h-full w-1/4 md:w-fit"
        >
          <Logo className="max-w-[50%] xl:max-w-[70%] w-full" />
        </Zoom>

        <div className="flex-1 flex justify-between items-center sm:w-fit">
          <Zoom triggerOnce className="flex-grow ">
            <NavigationList
              activeLinkId={activeLinkId}
              onLinkClick={handleLinkClick}
              listClassName="2xl:hidden justify-center items-center w-full px-3"
            />
          </Zoom>

          <Zoom triggerOnce className="w-[40%] 2xl:w-full 2xl:flex-grow px-3">
            <ManageAccountList
              listClassName="sm:hidden"
              onButtonClick={() => setActiveLinkId(null)}
            />
          </Zoom>

          <div className="hidden 2xl:flex 2xl:w-1/5 md:w-fit justify-end">
            <Slide triggerOnce direction="right">
              <Fade triggerOnce delay={200}>
                <FontAwesomeIcon
                  icon={faAlignJustify}
                  className="text-2xl text-black  hover:text-secondary cursor-pointer"
                  onClick={openSlidingPanel}
                />
              </Fade>
            </Slide>
          </div>
        </div>
      </nav>

      <MenuSlidingPanel
        activeLinkId={activeLinkId}
        onLinkClick={handleLinkClick}
        isPanelOpen={isSlidingPanelVisible}
        onClose={closeSlidingPanel}
      />
    </>
  );
};

import { FC, useEffect } from "react";

import { IframeContainer } from "../components/common/iframe-container.component";
import { generateLink } from "../utils/generate-link";
import { LinkModule } from "../enums";

export const QuickPayment: FC = () => {
  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, []);

  const searchParams = new URLSearchParams(window.location.search);
  const packId = searchParams.get("packId");
  const cid = searchParams.get("cid");

  const additionalPath = `/api/public/getPaymentLink?packId=${packId}&cid=${cid}`;
  const link = generateLink(LinkModule.PUBLIC, additionalPath);

  return (
    <IframeContainer title="Quick payment" link={link} id="quickPayment" />
  );
};

import { FC, useState } from "react";

import { Loader } from "./loader.component";

interface Props {
  title: string;
  link: string;
  id?: string;
}

export const IframeContainer: FC<Props> = ({ title, link, id }) => {
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);

  const hideLoader = () => setIsLoaderVisible(false);

  return (
    <div className="py-5 h-200 relative">
      <Loader isVisible={isLoaderVisible} />
      <iframe
        title={title}
        id={id}
        className="h-200 w-full"
        src={link}
        onLoad={hideLoader}
      />
    </div>
  );
};

import React, { FC } from "react";

import { Header } from "../components/layout/header.component";
import { Services } from "../components/layout/services.component";
import { Pricing } from "../components/layout/pricing.component";
import { Feedbacks } from "../components/layout/feedbacks.component";

export const HomePage: FC = () => {
  return (
    <>
      <Header />
      <Services />
      <Pricing />
      <Feedbacks />
    </>
  );
};

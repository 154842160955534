import React from "react";
import { Helmet } from "react-helmet-async";

export const MetaPixel = () => {
  const REACT_APP_GOOGLE_TAG_MANAGER_ID =
    process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
  const REACT_APP_FACEBOOK_PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL_ID;

  const facebookPixelImgUrl = `https://www.facebook.com/tr?id=${REACT_APP_FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1`;

  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtm.js?id=${REACT_APP_GOOGLE_TAG_MANAGER_ID}`}
      ></script>
      <script
        src={`https://connect.facebook.net/signals/config/${REACT_APP_FACEBOOK_PIXEL_ID}?v=2.9.147&amp;r=stable&amp;domain=pizzatime.cc&amp;hme=20c913bdcd4be51a752120153aa5caaecb3ee86c7f26cf737846e40b202aba68&amp;ex_m=62%2C106%2C94%2C98%2C53%2C3%2C88%2C61%2C14%2C86%2C79%2C44%2C46%2C150%2C153%2C164%2C160%2C161%2C163%2C25%2C89%2C45%2C68%2C162%2C145%2C148%2C157%2C158%2C165%2C115%2C13%2C43%2C169%2C168%2C117%2C16%2C29%2C32%2C1%2C36%2C57%2C58%2C59%2C63%2C83%2C15%2C12%2C85%2C82%2C81%2C95%2C97%2C31%2C96%2C26%2C22%2C146%2C149%2C124%2C24%2C9%2C10%2C11%2C5%2C6%2C21%2C19%2C20%2C49%2C54%2C56%2C66%2C90%2C23%2C67%2C8%2C7%2C71%2C41%2C18%2C92%2C91%2C17%2C4%2C73%2C80%2C72%2C78%2C40%2C39%2C77%2C33%2C35%2C76%2C48%2C74%2C28%2C37%2C65%2C0%2C84%2C75%2C2%2C30%2C55%2C34%2C93%2C38%2C70%2C60%2C99%2C52%2C51%2C27%2C87%2C50%2C47%2C42%2C69%2C64%2C100`}
        async
      ></script>
      <script>
        {`
            !(function (f, b, e, v, n, t, s) {
                if (f.fbq) return;
                n = f.fbq = function () {
                    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
                };
                if (!f._fbq) f._fbq = n;
                n.push = n;
                n.loaded = !0;
                n.version = "2.0";
                n.queue = [];
                t = b.createElement(e);
                t.async = !0;
                t.src = v;
                s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s);
            })(
                window,
                document,
                "script",
                "https://connect.facebook.net/en_US/fbevents.js"
            );
            fbq("init", ${REACT_APP_FACEBOOK_PIXEL_ID});
            fbq("track", "PageView");
        `}
      </script>
      <noscript>
        {`<img
              height="1"
              width="1"
              alt="facebook"
              style={{ display: "none" }}
              src="${facebookPixelImgUrl}"
            />`}
      </noscript>
    </Helmet>
  );
};

import { Helmet } from "react-helmet-async";

export const LiveChat = () => {
  const REACT_APP_LIVECHAT_LICENCE = process.env.REACT_APP_LIVECHAT_LICENCE;
  const REACT_APP_LIVECHAT_LCV = process.env.REACT_APP_LIVECHAT_LCV;
  
  return (
    <Helmet>
      <script
        async
        type="text/javascript"
        src={`https://connect.livechatinc.com/api/v1/script/${REACT_APP_LIVECHAT_LICENCE}/widget.js?lcv=${REACT_APP_LIVECHAT_LCV}`}
      ></script>
    </Helmet>
  );
};

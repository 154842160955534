import { FC, SVGProps } from "react";

export const CircleIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 44 44" width={16} {...props}>
    <g id="Group_42" data-name="Group 42" transform="translate(-761 -1605)">
      <ellipse
        id="Ellipse_22"
        data-name="Ellipse 22"
        cx="22"
        cy="21"
        rx="22"
        ry="21"
        transform="translate(761 1605)"
        fill="currentColor"
        opacity="30%"
      />
      <circle
        id="Ellipse_21"
        data-name="Ellipse 21"
        cx="13"
        cy="13"
        r="13"
        transform="translate(770 1613)"
        fill="#fff"
      />
      <circle
        id="Ellipse_20"
        data-name="Ellipse 20"
        cx="11"
        cy="11"
        r="11"
        transform="translate(772 1615)"
        fill="currentColor"
      />
    </g>
  </svg>
);

import React, { FC, useEffect } from "react";

import { IframeContainer } from "../components/common/iframe-container.component";
import { LinkModule } from "../enums";
import { generateLink } from "../utils/generate-link";

export const MyAccountPage: FC = () => {
  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, []);

  const link = generateLink(LinkModule.PORTAL);

  return <IframeContainer title="My Account" link={link} id="myAccount" />;
};
